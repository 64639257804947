import { Grid, Box, Typography, Card } from "@mui/material";
import React from "react";
import NFTIcon from "./../../assets/icons/ConsultingIcons/NFTIcon.png";
import IotIcon from "./../../assets/icons/ConsultingIcons/IotIcon.png";
import SupplychainIcon from "./../../assets/icons/ConsultingIcons/SupplychainIcon.png";
import HealthcareIcon from "./../../assets/icons/ConsultingIcons/HealthcareIcon.png";
import RealEstateIcon from "./../../assets/icons/ConsultingIcons/RealEstateIcon.png";
import LogisticsIcon from "./../../assets/icons/ConsultingIcons/LogisticsIcon.png";
import AutomotiveIcon from "./../../assets/icons/ConsultingIcons/AutomotiveIcon.png";
import InsuranceIcon from "./../../assets/icons/ConsultingIcons/InsuranceIcon.png";
import Defi from "./../../assets/icons/ConsultingIcons/defi.png";
import LoyaltyprogramsIcon from "./../../assets/icons/ConsultingIcons/LoyaltyprogramsIcon.png";
import "./index.css";

export const ConsultingSection = () => {
  console.log("consulting section")
  return (
    <Box className="consulting-section-container">
      <Box className="consulting-title-container">
        <Typography variant="caption">serving every major industry</Typography>
        <Typography variant="h2" className="consulting-title-title">
          We understand your business
        </Typography>
        <Typography variant="body2" className="consulting-title-body">
          Our experienced team has worked with organizations of all sizes - from startups and SMBs to large enterprises on leveraging the power of blockchain to grow and scale
        </Typography>
      </Box>
      <Box className="consulting-grid-container">
        <Box className="consulting-grid-card tl">
          <img src={NFTIcon} height="100px"></img>
          <Typography variant="h6">NFT</Typography>
        </Box>


        <Box className="consulting-grid-card mtr">
          <img src={IotIcon} height="100px"></img>
          <Typography variant="h6">IoT</Typography>
        </Box>


        <Box className="consulting-grid-card">
          <img src={SupplychainIcon} height="100px"></img>
          <Typography variant="h6">Supply Chain</Typography>
        </Box>


        <Box className="consulting-grid-card ">
          <img src={HealthcareIcon} height="100px"></img>
          <Typography variant="h6">Healthcare</Typography>
        </Box>


        <Box className="consulting-grid-card dtr">
          <img src={RealEstateIcon} height="100px"></img>
          <Typography variant="h6">Real Estate</Typography>
        </Box>


        <Box className="consulting-grid-card dbl">
          <img src={LogisticsIcon} height="100px"></img>
          <Typography variant="h6">Logistics</Typography>
        </Box>


        <Box className="consulting-grid-card">
          <img src={AutomotiveIcon} height="100px"></img>
          <Typography variant="h6">Automotive</Typography>
        </Box>


        <Box className="consulting-grid-card">
          <img src={InsuranceIcon} height="100px"></img>
          <Typography variant="h6">Insurance</Typography>
        </Box>


        <Box className="consulting-grid-card mbl">
          <img src={Defi} height="100px"></img>
          <Typography variant="h6">DeFi</Typography>
        </Box>


        <Box className="consulting-grid-card br">
          <img src={LoyaltyprogramsIcon} height="100px"></img>
          <Typography variant="h6">Loyalty programs</Typography>
        </Box>

      </Box>
    </Box>
  );
};
export default ConsultingSection;
