import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { FinalApp } from "./pages";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Druk-Wide-Bold/Druk-Wide-Bold.ttf";
// 400
import "./fonts/Manrope/Manrope-Regular.ttf";
import "./fonts/Manrope/Manrope-Medium.ttf";
import "./fonts/Manrope/Manrope-Bold.ttf";
import "./fonts/Manrope/Manrope-ExtraBold.ttf";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme(
  {
    palette: {
      background: {
        default: '#FFFF',
        paper: '#242629'
      },
      primary: {
        main: '#49D6D2',
      },
      secondary: {
        main: '#FB4D63'
      }
    },
    typography: {
      allVariants: {
        color: '#FFF'
      }
    }
  }
)


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <FinalApp />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
