import { ThemeProvider } from "@mui/material";
import { LandingPage } from "./LandingPage";
import Customtheme from "../theme";
// import "../css/style.css"

export const FinalApp = () => {
  return (
    <ThemeProvider theme={Customtheme}>
      <LandingPage />
    </ThemeProvider>
  );
};

export { };
