import React, { lazy, Suspense } from "react";
import Footer from "../Components/Footer";
import { NavbarUI } from "../Components/Navbar";
import QuoteSection from "./QuoteSection";
import { ConsultingSection } from "./ConsultingSection";
import { DeliveryProcess } from "./DeliveryProcess";
import { ScheduleCallsection } from "./ScheduleCallsection";
import ServicesSection from "./ServicesSection";
import TechnologiesSection from "./TechnologiesSection";
import { TrustedSection } from "./TrustedSection";
import { Box } from "@mui/material";
import './index.css'

// const ConsultingSection = lazy(() => import('./ConsultingSection'));
// const DeliveryProcess = lazy(() => import('./DeliveryProcess'));
// const ScheduleCallsection = lazy(() => import('./ScheduleCallsection'));
// const ServicesSection = lazy(() => import('./ServicesSection'));
// const TechnologiesSection = lazy(() => import('./TechnologiesSection'));
// const TrustedSection = lazy(() => import('./TrustedSection'));


export const LandingPage = () => {
  return (
    <Box className="layout">
      <NavbarUI />
      <QuoteSection />
      <Suspense fallback={<></>}>
        <TrustedSection />
        <ConsultingSection />
        <DeliveryProcess />
        <TechnologiesSection />
        <ServicesSection />
        <ScheduleCallsection />
        <Footer />

      </Suspense>

    </Box>
  );
};

export { };
