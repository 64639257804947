import axios from 'axios';

// const fabFormId = "8n9xP5X"
const fabFormId = "S0XC0TE"

export const formSubmission = async (formdata) => {
    console.log(formdata);
    try {
        const { data } = await axios.post(`https://fabform.io/f/${fabFormId}`,
            { ...formdata });
        return { data };
    } catch (err) {
        console.log(err);
    }
}