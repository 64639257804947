import { createTheme } from "@mui/material";


const Customtheme= createTheme({
  components:{
    MuiLink:{
      defaultProps:{
        underline: "none"
      }
    },
    MuiAppBar:{
      defaultProps:{
        color: 'transparent',
        position:'relative'
      }
    },
    MuiTextField:{
      defaultProps:{
        margin: 'dense'
      }
    }
  },
  palette:{
    background:{
      default:'rgba(24, 25, 27, 1)',
      paper:'#242629'
    },
    primary:{
      main:'#49D6D2',
    },
    secondary:{
      main:'#FB4D63'
    },
    text:{
      primary:'#FFFFFF',
      secondary:'#FFFFFF',
      disabled:'#FFF'
    },
    info:{
      main: "rgba(36, 38, 41, 1)"
    }
  },
  typography:{
    allVariants:{
      color:'#FFF'
    },
    h1:{
      fontFamily:"Druk-Wide-Bold",
      fontWeight:300,
      fontSize:'2.7rem',
    },
    h2:{
      fontFamily:"Druk-Wide-Bold",
      fontSize:"2.2rem"
    },
    h3:{
      fontFamily:"Druk-Wide-Bold",
      fontWeight:200,
      fontSize:'1.6rem'
    },
    h4:{
      fontFamily:"Manrope-Medium",
      fontWeight:600,
      fontSize:'1.1rem'
    },
    h5:{
      fontFamily:'Manrope-Medium',
      fontWeight:800,
      letterSpacing: 1.2,
      fontSize:'1.5rem'
    },
    h6:{
      fontFamily:'Manrope-Medium',
      fontWeight:600,
      fontSize:'1.3rem'
    },
    body1:{
      fontFamily:"Manrope-Medium",
      fontWeight:300,
      fontSize: '0.9rem',
      color:'rgba(255, 255, 255, 0.5)',
    },
    body2:{
      fontFamily:"Manrope-Medium",
      color:'rgba(255, 255, 255, 0.7)',
      fontSize:'1.1rem'
    },
    button:{
      fontFamily:"Manrope-Medium",
      textTransform: "none"
    },
    caption:{
      fontFamily:"Manrope-Medium",
      color: "#49D6D2",
      textTransform: "uppercase",
      fontWeight:600,
      fontSize:'0.8rem'
    }
  },
  
})

export default Customtheme;