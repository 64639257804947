import { Box, Container } from "@mui/material";
import React from "react";
import { TextSection } from "./TextSection";
import { QuoteForm } from "./QuoteForm";
import './index.css'


export const QuoteSection = () => {
  return (
    <Box className="quote-container">
      <div className="quote-container-inner">
        <TextSection />
        <QuoteForm />
      </div>
    </Box>
  );
};

export default QuoteSection;
