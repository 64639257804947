import React, { useState } from "react";
import {
    Button,
    Modal,
    Box,
    TextField,
    Typography,
} from "@mui/material";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

type PrivateProps = {
    open: boolean,
    onClose: Function
}

export const ModalBox = ({ open, onClose }: PrivateProps) => {
    return (
        <Modal
            open={open}
            onClose={() => onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-box" >
                <Box className="modal-check">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    {/* <i className="fa-sharp fa-solid fa-circle-check"></i> */}
                </Box>
                <Box onClick={() => onClose()} className="modal-time">
                    <FontAwesomeIcon icon={faTimesCircle} />
                </Box>
                <Typography sx={{ color: 'black' }} id="modal-modal-title" variant="h6" component="h2">
                    Thanks for getting in touch! We will get back to you soon
                </Typography>

            </Box>
        </Modal>
    )
}