import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { BRAND_NAME } from "../../../constant";
import './index.css'

export const TextSection = () => {
  return (
    <Box className='text-container'>

      <Stack direction='column' className="quote-title">
        <Typography variant="h1" className="">FORGING THE NEXT-</Typography>
        <Typography variant="h1">GENERATION OF</Typography>
        <Typography variant="h1">TRUST AND FINANCE</Typography>
      </Stack>


      <Typography variant="body2" className="quote-caption">
        {BRAND_NAME} is a group of individuals who are behind some of the most well-regarded Web3 projects. Now, get best-in-class expertise and partnership to power your blockchain projects, and experience unmatchable levels of skillful development that brings you result.
      </Typography>

      <Box className="button-container">
        <Button className="text-btn" variant="contained" color="secondary">GET A FREE QUOTE</Button>
        <Button className="text-btn" variant="contained" color="info" disableElevation>CONTACT US</Button>
        {/* <button className="text-btn text-btn-primary" >GET A FREE QUOTE</button>
        <button className="text-btn text-btn-dark" >CONTACT US</button> */}
      </Box>
    </Box>
  );

};
