import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { BRAND_NAME } from "../../constant";
import chain from "./../../assets/icons/serviceIcons/chain.png";
import coinStack from "./../../assets/icons/serviceIcons/coinStack.png";
import contract from "./../../assets/icons/serviceIcons/contract.png";
import ethereum from "./../../assets/icons/serviceIcons/ethereum.png";
import ico from "./../../assets/icons/serviceIcons/Ico.png";
import lockCircle from "./../../assets/icons/serviceIcons/lockCircle.png";
import network from "./../../assets/icons/serviceIcons/network.png";
import nft from "./../../assets/icons/serviceIcons/nft.png";
import People from "./../../assets/icons/serviceIcons/People.png";
import "./index.css";
export const ServicesSection = () => {
  return (
    <Box className="services-container">
      <Box className="services-container-title">
        <Typography variant="caption">OUR EXPERT SERVICES</Typography>
        <Typography variant="h2">All our services</Typography>
      </Box>
      <Box className="services-grid-container">
        <Box className="services-grid-item">
          <img src={network} height="120px" />
          <Typography variant="h6">Blockchain wallet development</Typography>
          <Typography variant="body2">
            We have expert blockchain developers that can secure your bitcoins
            and other cryptocurrency to safeguard and your digital assets and
            increase the level of your online wallets.
          </Typography>
        </Box>
        <Box className="services-grid-item">
          <img src={chain} height="120px" />
          <Typography variant="h6">Multi chain/Supply chain</Typography>
          <Typography variant="body2">
            Our blockchain developers have created several multi chain/supply chains to assure transparency in the supply chain through easy and traceable solutions.
          </Typography>
        </Box>
        <Box className="services-grid-item">
          <img src={People} height="120px" />
          <Typography variant="h6">Crowdsale</Typography>
          <Typography variant="body2">
            We have a group of expert blockchain coders that can create the most ideal Crowdsale contracts for you to facilitate better management of your cryptocoins.
          </Typography>
        </Box>
        <Box className="services-grid-item">
          <img src={ethereum} height="120px" />
          <Typography variant="h6">Ethereum</Typography>
          <Typography variant="body2">
            {BRAND_NAME} has extensive expertise in the Etherium technology and framework for all your Etherium development needs.
          </Typography>
        </Box>
        <Box className="services-grid-item">
          <img src={contract} height="120px" />
          <Typography variant="h6">Smart contract</Typography>
          <Typography variant="body2">
            Our blockchain developers understand your needs and accordingly come up with smart contract management platforms and eWallet apps.
          </Typography>
        </Box>
        <Box className="services-grid-item">
          <img src={coinStack} height="120px" />
          <Typography variant="h6">Coin trading</Typography>
          <Typography variant="body2">
            A coin trading platform is a platform for exchanging crypto currencies. To facilitate this, our platform has Polygon, Binance, coinGecko APIs among several others.
          </Typography>
        </Box>
        <Box className="services-grid-item">
          <img src={nft} height="120px" />
          <Typography variant="h6">NFT Marketplace</Typography>
          <Typography variant="body2">
            Our NFT marketplace strikes the right balance between innovation and familiarity with innumerable existing online marketplace to ease out the trading and transactions experience of your users.
          </Typography>
        </Box>
        <Box className="services-grid-item">
          <img src={ico} height="120px" />
          <Typography variant="h6">ICO</Typography>
          <Typography variant="body2">
            We help you launch your offering, and offer strategic advice alongside our core technical offerings to help you generate capital for your business.
          </Typography>
        </Box>
        <Box className="services-grid-item">
          <img src={lockCircle} height="120px" />
          <Typography variant="h6">Private blockchain</Typography>
          <Typography variant="body2">
            Want to set up your own private blockchain for internal stakeholder usages? Our specialist developers help you setup your own enterprise-grade blockchain network as per your needs.
          </Typography>
        </Box>
      </Box>

    </Box>
  );
};

export default ServicesSection;
