import {
  Button,
  TextField,
  Typography,
  Box,
  Modal
} from "@mui/material";
import './index.css';
import React, { useState } from "react";
import { formSubmission } from "../../../services/formServices";
import { ModalBox } from "../../Components/ModalBox";

const initialValues = {
  Name: {
    FirstName: "First Name",
    LastName: "Last Name",
  },
  Message: "Write Message..",
  EmailAddress: "Your Email address",
  PhoneNumber: {
    AreaCode: 91,
    Number: 1234567890,
  },
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  color: "black",
};

export const QuoteForm = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const handleSubmit = async () => {
    console.log(name, email, phone, company, message);
    setError("");
    if (!name.length || !email.length || !phone.length || !message.length) {
      setError('Please complete the mandatory fields');
    } else {
      if (company.length) {
        try {
          await formSubmission({ name, email, phone, message, company })
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 5000)
        } catch (error) {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 5000)
        }
      } else {
        try {
          await formSubmission({ name, email, phone, message })
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 5000)
        } catch (error) {
          setOpen(true)
          setTimeout(() => {
            setOpen(false);
          }, 5000)
        }
      }
      setCompany('');
      setEmail('');
      setPhone('');
      setName('');
      setMessage('');
    }
  }
  return (
    <Box className="form-container">
      <Typography sx={{ textAlign: 'center' }} variant="h5">Get a quote from us in minutes</Typography>
      {/* @TODO: Grammer is wrong */}
      <Box className="form">
        <Typography className="form-error oneLiner">{error}</Typography>
        <TextField value={name} size="small" name="name" className='form-field oneLiner' variant="filled" label="Name" onChange={(e) => setName(e.target.value)}></TextField>
        {/* <TextField size="small"  className='form-field' variant="filled" label="Phone Number"></TextField> */}
        <TextField value={phone} size="small" name="phone" className='form-field oneLiner' variant="filled" label="Phone Number" onChange={(e) => setPhone(e.target.value)}></TextField>

        <TextField value={email} size="small" name="email" className='form-field oneLiner' variant="filled" label="Email address" onChange={(e) => setEmail(e.target.value)}></TextField>
        <TextField value={company} size="small" name="company" className='form-field oneLiner' variant="filled" label="Company Name (Optional)" onChange={(e) => setCompany(e.target.value)}></TextField>
        <TextField size="small"
          variant="filled"
          value={message}
          name="message"
          onChange={(e) => setMessage(e.target.value)}
          label="Write message..."
          multiline
          rows={3}
          className='form-field oneLiner'
        ></TextField>


        <ModalBox open={open} onClose={() => setOpen(false)} />
      </Box>

      <Button
        variant="contained"
        color="secondary"
        className="form-submit-button"
        onClick={handleSubmit}
      >
        SUBMIT
      </Button>
    </Box>
  );
};
