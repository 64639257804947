import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { formSubmission } from "../../services/formServices";
import { ModalBox } from "../Components/ModalBox";
import "./index.css";

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  color: "black",
  display: "flex",
  flexDirection: "column"
};

export const ScheduleCallsection = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const handleSubmit = () => {
    console.log(name, email, phone, company, message);
    setError("");
    if (!name.length || !email.length || !phone.length || !message.length) {
      setError('Please complete the mandatory fields');
    } else {
      if (company.length) {
        formSubmission({ name, email, phone, message, company }).then((res) => {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 5000)
        }).catch((error) => {
          setOpen(true)
          setTimeout(() => {
            setOpen(false);
          }, 5000)
        })

      } else {
        formSubmission({ name, email, phone, message }).then((res) => {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 5000)
        }).catch((error) => {
          setOpen(true)
          setTimeout(() => {
            setOpen(false);
          }, 5000)
        })
      }
      setCompany('');
      setEmail('');
      setPhone('');
      setName('');
      setMessage('');
    }
  }

  return (
    <Box className="schedule-call-container">
      <Box className="schedule-call-title">
        <Typography variant="h3">
          Have a project idea? Schedule a call with us!
        </Typography>
        <Typography variant="body2">
          Fill the form and we will get back to you ASAP to take things forward.
        </Typography>
      </Box>
      <Box className="schedule-form-container">
        <Typography className="form-error form-grid-error">{error}</Typography>
        <TextField value={name} label="Name" variant="filled" className="form-grid-item1" onChange={(e) => setName(e.target.value)}></TextField>
        <TextField value={phone} label="Phone Number" className="form-grid-item1" variant="filled" onChange={(e) => setPhone(e.target.value)}></TextField>
        <TextField value={email} label="Email" className="form-grid-item1" variant="filled" onChange={(e) => setEmail(e.target.value)}></TextField>
        <TextField value={company} label="Company (Optional)" className="form-grid-item1" variant="filled" onChange={(e) => setCompany(e.target.value)}></TextField>
        <TextField value={message} label="Write Message..." className="form-grid-item2" variant="filled" multiline rows={4} onChange={(e) => setMessage(e.target.value)}></TextField>
      </Box>
      {/* <FormControlLabel control={<Checkbox />} label="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since, when an unknown printer" className="schedule-form-checkbox" /> */}
      <Button size='large' variant="contained" color="secondary" className="schedule-form-submit" onClick={handleSubmit}><Typography variant='h6'>GET A FREE CONSULTATION</Typography></Button>

      <ModalBox open={open} onClose={() => setOpen(false)} />
    </Box>
  );
};

export default ScheduleCallsection;
