import { Box, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import React from "react";
import Unknown from "./../../assets/icons/unknown.png";
import OpenZepp from "./../../assets/icons/openzepp.png";
import Penrose from "./../../assets/icons/penrose.png";
import VerizonIcon from "./../../assets/icons/VerizonIcon.png";
import OpenSea from "./../../assets/icons/opensea.png";
import PanCake from "./../../assets/icons/pancake.png";
import './index.css';

export const TrustedSection = () => {
  return (
    <Box className="trusted-section-container">
      <Typography variant="h6" color='primary' className="trusted-section-title desktop"> Trusted By</Typography>
      <Typography variant="body1" color='primary' className="trusted-section-title mobile"> Trusted by top fastest growing companies</Typography>
      {/* <span>Trusted By</span> */}
      <Box className="trusted-section-icons">

        <img src={Unknown} height='24px'></img>
        <img src={OpenZepp} height='29px'></img>
        <img src={Penrose} height='35px'></img>
        <img src={VerizonIcon} height='23px'></img>
        <img src={OpenSea} height='33px'></img>
        <img src={PanCake} height='29px'></img>
      </Box>

    </Box>
  );
};

export default TrustedSection;
