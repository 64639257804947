import { Box, Link, Stack, Typography } from "@mui/material";
import React from "react";
import facebookIcon from "./../../../assets/icons/facebookIcon.png";
import twitterIcon from "./../../../assets/icons/twitterIcon.png";
import linkedInIcon from "./../../../assets/icons/linkedInIcon.png";
import "./index.css";
import { BRAND_NAME } from "../../../constant";

export const Footer = () => {
  return (
    <Box className="footer-container">
      <Box className="footer-stacks-container">
        <Box className="footer-stack">
          <Link href="/">
            <Typography variant="h6">Meet {BRAND_NAME}</Typography>
          </Link>
          {/* <Link href="/">
            <Typography variant="body2">About Us</Typography>
          </Link>
          <Link href="/">
            <Typography variant="body2">Careers</Typography>
          </Link>
          <Link href="/">
            <Typography variant="body2">Insights</Typography>
          </Link>
          <Link href="/">
            <Typography variant="body2">Privacy Policy</Typography>
          </Link>
          <Link href="/">
            <Typography variant="body2">Terms and Conditions</Typography>
          </Link> */}
        </Box>
        <Box className="footer-stack">
          <Typography variant="h6">Products</Typography>
          <Typography variant="body2">NFT Marketplace</Typography>
          <Typography variant="body2">Decentralized Exchange</Typography>
          <Typography variant="body2">Custom Blockchain</Typography>
          <Typography variant="body2">RPA</Typography>
          <Typography variant="body2">Crowdsale platform</Typography>
        </Box>
        <Box className="footer-stack">
          <Typography variant="h6">Services</Typography>
          <Typography variant="body2">DeFi development</Typography>
          <Typography variant="body2">Blockchain development</Typography>
          <Typography variant="body2">Metaverse development</Typography>
          <Typography variant="body2">Business automation</Typography>
          <Typography variant="body2">Smart contract development</Typography>
        </Box>
        {/* <Box className="footer-stack">
          <Typography variant="h6">Email</Typography>
          <Link href="/">
            <Typography variant="body2">hell0@0xlegion.com</Typography>
          </Link>
          <Typography variant="h6">Twitter</Typography>
          <Link href="/">
            <Typography variant="body2"></Typography>
          </Link>
          <Typography variant="h6">Discord</Typography>
          <Link href="/">
            <Typography variant="body2"></Typography>
          </Link>
        </Box> */}

      </Box>
      <hr />
      {/* <Box className="footer-social-container">
        <Link href="/">
          <img src={facebookIcon} height="22px" />
        </Link>
        <Link href="/">
          <img src={linkedInIcon} height="22px" />
        </Link>
        <Link href="/">
          <img src={twitterIcon} height="22px" />
        </Link>
      </Box> */}
      <Box className="footer-copyright-container">

        <Typography variant="body2">2022 {BRAND_NAME} © All rights reserved</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
