import { Box, Card, Grid, Typography } from "@mui/material";
import graph from "./../../assets/icons/DeliveryIcons/graph.png";
import search from "./../../assets/icons/DeliveryIcons/search.png";
import create from "./../../assets/icons/DeliveryIcons/create.png";
import verified from "./../../assets/icons/DeliveryIcons/verified.png";
import rocket from "./../../assets/icons/DeliveryIcons/rocket.png";
import { BRAND_NAME } from "../../constant";

export const DeliveryProcess = () => {
  return (
    <Box className="delivery-section-container">
      <Box className="delivery-title-container">
        <Typography variant="caption">transparent delivery process</Typography>
        <Typography variant="h2" className="delivery-title-title">
          With you every step of the way
        </Typography>
        <Typography variant="body2" className="delivery-title-body">
          We work with you at every stage of the production process, defining
          and specifying adoption and integration challenges your business
          faces. Our distribution R&D team finds the best way to implement
          blockchain into your operation, matching your business requirements
          and long-term strategic goals.
        </Typography>
      </Box>
      <Box className="delivery-grid-container">
        <Box className="delivery-grid-item">
          <img src={graph} height="110px"></img>
          <Typography variant="h6">Business requirement analysis</Typography>
          <Typography variant="body2">
            We analyze your current business, the pain-points you experience and
            your goals in detail to ensure we come up with the solution that
            helps you achieve those goals.
          </Typography>
        </Box>
        <Box className="delivery-grid-item">
          <img src={search} height="110px"></img>
          <Typography variant="h6">
            Market research and competitor analysis
          </Typography>
          <Typography variant="body2">
            We deep-dive into the latest cutting-edge solutions that competitors
            within your industry as well as companies from other relevant
            sectors have implemented. And from this, we take another step
            forward to deliver you better results.
          </Typography>
        </Box>
        <Box className="delivery-grid-item">
          <img src={create} height="110px"></img>
          <Typography variant="h6">Creation of potential use cases</Typography>
          <Typography variant="body2">
            We do not build things just for the sake of it. Every solution
            implemented is solidly backed by deep understanding of how it will
            affect your business, and the use cases that will enabled through
            it.
          </Typography>
        </Box>
        <Box className="delivery-grid-item">
          <img src={verified} height="110px"></img>
          <Typography variant="h6">
            Choosing the appropriate blockchain
          </Typography>
          <Typography variant="body2">
            We leverage our team's extensive experience working with different
            blockchains to select the most suitable one for your use-case, with
            the right mix of speed, efficiency and security.
          </Typography>
        </Box>
        <Box className="delivery-grid-item">
          <img src={rocket} height="110px"></img>
          <Typography variant="h6">
            Developing & launching a pilot project
          </Typography>
          <Typography variant="body2">
            Finally, our team starts building and delivering on your
            requirements. We understand the importance each day has to your
            business - timely, high quality deliverables is the core tenet which{" "}
            {BRAND_NAME} strives for.
          </Typography>
        </Box>
        {/* <Grid container spacing={1}>
          <Grid item xs={4}>
            <Card>
              <img src={graph} height="60px"></img>
              <Typography variant="h6">
                Business Requirement analysis
              </Typography>
              <Typography variant="body2">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis
                aperiam fugit molestias similique suscipit soluta quos nihil
                dolores ut adipisci?\
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <img src={search} height="60px"></img>
              <Typography variant="h6">
                Market research and competitor analysis
              </Typography>
              <Typography variant="body2">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis
                aperiam fugit molestias similique suscipit soluta quos nihil
                dolores ut adipisci?\
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <img src={create} height="60px"></img>
              <Typography variant="h6">
                Creation of potencial use cases
              </Typography>
              <Typography variant="body2">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis
                aperiam fugit molestias similique suscipit soluta quos nihil
                dolores ut adipisci?\
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <img src={verified} height="60px"></img>
              <Typography variant="h6">
                Choosing the appropriate blockchain
              </Typography>
              <Typography variant="body2">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis
                aperiam fugit molestias similique suscipit soluta quos nihil
                dolores ut adipisci?\
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <img src={rocket} height="60px"></img>
              <Typography variant="h6">
                Developing & launching a pilot project
              </Typography>
              <Typography variant="body2">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis
                aperiam fugit molestias similique suscipit soluta quos nihil
                dolores ut adipisci?\
              </Typography>
            </Card>
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  );
};

export default DeliveryProcess;
