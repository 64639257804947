import React, { useState } from "react";
import {
  AppBar,
  Button,
  IconButton,
  Link,
  Toolbar,
  Drawer,
  Typography,
  Box,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { BiMenu } from "react-icons/bi";
import { alpha, styled } from "@mui/material/styles";
import "./index.css";

import Logo from "../../../assets/img/logo.svg";

export const NavbarLinks = [
  { displayName: "Products", link: "/" },
  { displayName: "Services", link: "/" },
  { displayName: "Solutions", link: "/" },
  { displayName: "Technologies", link: "/" },
  { displayName: "Company", link: "/" },
];

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  justifyContent: "space-between",
  padding: "0px 20px",
}));

export const NavbarUI = () => {
  const [state, setState] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.MouseEvent) => {
    setState(open);
  };
  const drawerList = (
    <Box onClick={toggleDrawer(false)}>
      <List>
        {NavbarLinks.map((e) => (
          <ListItem key={e.displayName}>
            <ListItemButton>
              <Link href={e.link} className="navbar-links">
                <Typography variant="body2">{e.displayName}</Typography>
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar elevation={0} position="fixed">
      <CustomToolbar>
        <Link href="/" className="navbarLogo">
          <img src={Logo}></img>
        </Link>
        {/* <Box className='navbar-links-container' >
          {NavbarLinks.map((e) => (
            <Link href={e.link} className="navbar-links"><Typography variant='body2'>{e.displayName}</Typography></Link>
          ))}
        </Box> */}
        <Button
          variant="outlined"
          color="secondary"
          className="navbar-contact-button"
        >
          <Typography variant="button">Contact us</Typography>{" "}
        </Button>
        {/* <IconButton className="show-menu-button" onClick={toggleDrawer(true)}><BiMenu /></IconButton> */}
        <Drawer
          className="mobile-menu-drawer"
          anchor="right"
          onClose={toggleDrawer(false)}
          open={state}
        >
          {drawerList}
        </Drawer>
      </CustomToolbar>
    </AppBar>
  );
};

export default NavbarUI;
