import { Grid, Box, Paper, Typography } from "@mui/material";
import React from "react";
import binanceCoin from "./../../assets/icons/Protocols/binance-coin-(bnb).png";
import bitcoin from "./../../assets/icons/Protocols/bitcoin-(btc).png";
import celo from "./../../assets/icons/Protocols/celo-(celo).png";
import dash from "./../../assets/icons/Protocols/dash-(dash).png";
import ethereuem from "./../../assets/icons/Protocols/ethereum-(eth).png";
import html from "./../../assets/icons/Protocols/html-3.png";
import monero from "./../../assets/icons/Protocols/monero-(xmr).png";
import xrp from "./../../assets/icons/Protocols/xrp-(xrp).png";
import nebulas from "./../../assets/icons/Protocols/nebulas-(nas).png";
import oceanProtocol from "./../../assets/icons/Protocols/ocean-protocol-(ocean).png";
import "./index.css";
export const TechnologiesSection = () => {
  return (
    <Box className="technology-container">
      <Typography variant="h2">Technologies we work with</Typography>
      <Box className="technology-grid-container">
        <Box className="technology-grid-item">
          <img src={bitcoin} height="40px" />
          <Typography variant="body2">Bitcoin</Typography>
        </Box>
        <Box className="technology-grid-item">
          <img src={ethereuem} height="40px" />
          <Typography variant="body2">Ethereum</Typography>
        </Box>
        <Box className="technology-grid-item">
          <img src={binanceCoin} height="40px" />
          <Typography variant="body2">Binance</Typography>
        </Box>
        <Box className="technology-grid-item">
          <img src={oceanProtocol} height="40px" />
          <Typography variant="body2">Ocean</Typography>
        </Box>
        <Box className="technology-grid-item">
          <img src={celo} height="40px" />
          <Typography variant="body2">Celo</Typography>
        </Box>

        <Box className="technology-grid-item">
          <img src={xrp} height="40px" />
          <Typography variant="body2">XRP</Typography>
        </Box>
        <Box className="technology-grid-item">
          <img src={dash} height="40px" />
          <Typography variant="body2">Dash</Typography>
        </Box>

        <Box className="technology-grid-item">
          <img src={nebulas} height="40px" />
          <Typography variant="body2">Nebulas</Typography>
        </Box>



        <Box className="technology-grid-item">
          <img src={monero} height="40px" />
          <Typography variant="body2">Monero</Typography>
        </Box>


        <Box className="technology-grid-item">
          <img src={html} height="40px" />
          <Typography variant="body2">HTML 3</Typography>
        </Box>


      </Box>
    </Box>
  );
};

export default TechnologiesSection;
